import { einvApiDateFormat, einvMomentDateFormat, weekdays, months } from "appConstants";
import moment, { Moment } from "moment";
import { NumberFormatOptions } from '@progress/kendo-react-intl';

type DownloadFile = {
    data: any;
    filename: string;
    mime: any;
    bom: any;
};

const downloadFile = ({ data, filename, mime, bom }: DownloadFile) => {
    var blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    var blob = new Blob(blobData, { type: mime || 'application/octet-stream' });

    var blobURL =
        window.URL && window.URL.createObjectURL
            ? window.URL.createObjectURL(blob)
            : window.webkitURL.createObjectURL(blob);
    var tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Fixes "webkit blob resource error 1"
    setTimeout(function () {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }, 200);
};

const odataInstrumentsSearchQuery = (query: string): string => {
    return `contains(tolower(Symbol), tolower('${query}')) or contains(tolower(Name), tolower('${query}')) or contains(tolower(Denomination), tolower('${query}')) or contains(tolower(IsinTicker), tolower('${query}')) or contains(tolower(InstrumentsType/Description), tolower('${query}'))`;
};

const formatDateTime = (date: Date | string | Moment | null | undefined): string | null => {
    if (!date) {
        return null;
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format();
};

const formatReadableDateTime = (date: Date | string | Moment | null | undefined, format: string | undefined = einvMomentDateFormat): string | null => {
    if (!date) {
        return null;
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format(format);
};


const formatDateForApiQuery = (date: Date | string | Moment | null | undefined): string => {
    if (!date) {
        return '';
    }
    let dateM: Moment = moment.isMoment(date) ? date : moment(date);

    if (!dateM.isValid()) {
        return date.toString();
    }

    return dateM.format(einvApiDateFormat);
};

const parseDateString = (value: string): Date | null => {
    let today: Date = new Date();
    if (!value) {
        return null;
    }

    //Se utilizar minutos y segundos estandarizados a fines de comparación entre fechas
    let momentValue = moment(value)
        .set('hour', today.getHours())
        .set('minute', today.getMinutes())
        .set('second', today.getSeconds())
        .set('millisecond', today.getMilliseconds());

    if (!momentValue.isValid()) {
        return null;
    }
    return momentValue.toDate();
};

const sortArrayByDateProperty = (array: any[], dateProperty: string): any[] => {
    return array.sort((a, b) => new Date(a[dateProperty]).getTime() - new Date(b[dateProperty]).getTime());
}

// Funcion genérica para ordenar arrays de objetos por una propiedad (especificada por parámetro)
function createCompareFn<T extends Object>(
    property: keyof T,
    sort_order: "asc" | "desc"
  ) {
    const compareFn = (a: T, b: T):number => {        
      const val1 = a[property] as unknown;
      const val2 = b[property] as unknown;
      const order = sort_order !== "desc" ? 1 : -1;
  
      switch (typeof val1) {
        case "number": {
          const valb = val2 as number;
          const result = val1 - valb;
          return result * order;
        }
        case "string": {
          const valb = val2 as string;
          const result = val1.localeCompare(valb);
          return result * order;
        }
        // add other cases like boolean, etc.
        default:
          return 0;
      };
    }

    return compareFn;
  }

const formatPercentage1Dec = (value: number | null | undefined): number => {
    if (!value)
        return 0;

    var fmtOpts: NumberFormatOptions = {
        style: 'currency',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1
    };

    return  parseFloat(value.toFixed(fmtOpts.maximumFractionDigits || 0));
};

const formatPercentage2Dec = (value: number | null | undefined): number => {
    if (!value)
        return 0;

    var fmtOpts: NumberFormatOptions = {
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };

    return parseFloat(value.toFixed(fmtOpts.maximumFractionDigits || 0));
};

const formatPercentage3Dec = (value: number | null | undefined): number => {
    if (!value)
        return 0;

    var fmtOpts: NumberFormatOptions = {
        style: 'currency',
        minimumFractionDigits: 3,
        maximumFractionDigits: 3
    };

    return parseFloat(value.toFixed(fmtOpts.maximumFractionDigits || 0));
};

// Dada una fecha, la devuelve en un string con formato YYYY-MM-DD
const formatDateToYYYYMMDD = (date: Date): string => {
    return date.toISOString().split('T')[0];
}

// Dada una fecha, obtiene el nombre de día de la semana que le corresponde
const getWeekdayName = (date: Date): string => {
    return weekdays[date.getDay()];
}

const getMonthName = (date: Date): string => {
    return months[date.getMonth()];
}

// Dada una fecha, devuelve true si es un día laborable (Lunes a Viernes)
const isWeekDay = (date: Date): boolean => {
  let dayNum = date.getDay();  

  return (dayNum > 0 && dayNum < 6); // 0 = Sunday, 6 = Saturday
}

// Data una fecha, obtiene el nombre del día laborable anterior
const getPreviousWorkingDayName = (date: Date): string => {
    date.setDate(date.getDate() - 1);

    while (!isWeekDay(date))
      date.setDate(date.getDate() - 1);
  
    return getWeekdayName(date);
}

const getDayNameForTranslation = (date: Date | undefined): string => {
    if (!date || date.toString() === '') 
        return '';

    return getWeekdayName(new Date(date));
};

const getMonthNameForTranslation = (date: Date | undefined): string => {
    if (!date || date.toString() === '')
        return '';

    return getMonthName(new Date(date));
};

const getKeyToTranslateErrorMessage = (key: string): string => {
    let keyToTranslate = '';

    switch (key) {
        case 'non-existent agents': {
            keyToTranslate = 'nonExistentAgent';
            break;
        }
        case 'non-existent cash movs currencies': {
            keyToTranslate = 'nonExistentCashMovementCurrencies';
            break;
        }
        case 'non-existent Cash movements currency price': {
            keyToTranslate = 'nonExistentCashMovementsCurrencyPrice';
            break;
        }
        case 'non-existent transaction to be canceled': {
            keyToTranslate = 'nonExistentTransactionToBeCanceled';
            break;
        }
        case 'duplicate cancellation movements': {
            keyToTranslate = 'duplicateCancellationMovements';
            break;
        }
        case 'duplicate movements': {
            keyToTranslate = 'transactionNumberAlreadyExists';
            break;
        }
        case 'non-existent portfolios': {
            keyToTranslate = 'nonExistentPortfolio';
            break;
        }
        case 'non-existent ClientId': {
            keyToTranslate = 'nonExistentClientId';
            break;
        }
        case 'non-existent markets': {
            keyToTranslate = 'nonExistentMarkets';
            break;
        }
        case 'non-existent agents type id': {
            keyToTranslate = 'nonExistentAgentTypeId';
            break;
        }
        case 'non-existent transactionType': {
            keyToTranslate = 'nonExistentTransactionType';
            break;
        }
        case 'non-existent Market': {
            keyToTranslate = 'nonExistentMarket';
            break;
        }
        case 'non-existent Holding movements instrument': {
            keyToTranslate = 'nonExistentHoldingMovementsInstrument';
            break;
        }
        case 'non-existent Holding movements currencies prices': {
            keyToTranslate = 'nonExistentHoldingMovementsCurrencyPrice';
            break;
        }
        case 'non-existent Holding movements Instrument type': {
            keyToTranslate = 'nonExistentHoldingMovementsInstrumentType';
            break;
        }
        case 'non-existent account number': {
            keyToTranslate = 'nonExistentAccountNumber';
            break;
        }
        case 'non-informed refMovementId': {
            keyToTranslate = 'nonInformedRefMovementId';
            break;
        }
        case 'non-found Cash movements Prices': {
            keyToTranslate = 'nonFoundCashMovementsPrices';
            break;
        }
        default: {
            keyToTranslate = key;
        }
    } 

    return keyToTranslate;
}

export { downloadFile, odataInstrumentsSearchQuery, formatDateTime, formatReadableDateTime, parseDateString, sortArrayByDateProperty, formatDateForApiQuery, createCompareFn,
    formatPercentage1Dec, formatPercentage2Dec, formatPercentage3Dec, getWeekdayName, getDayNameForTranslation, getMonthNameForTranslation, getKeyToTranslateErrorMessage }